import { gql, useQuery } from "@apollo/client";
import EventList from "../../components/EventList";
import { useParams } from "react-router-dom";

const UserEvents = () => {

    const { user_id } = useParams();

    const { data } = useQuery(gql`
        query getAllEvents(
            $createdBy: UUID
        ) {
            allEvents (
                filter: {
                    createdBy: {
                        equalTo: $createdBy
                    }
                }
            ) {
                nodes {
                    id
                    address
                    maxParticipants
                    name
                    picture
                    price
                    dateOfEvent
                    status
                }
            }
        }
    `, {
        variables: {
            createdBy: user_id
        },
        fetchPolicy: 'cache-and-network'
    });

    return (
        <div>
            {(data?.allEvents ?? []).length === 0 &&
                <p>You did not create any event yet</p>
            }
            <EventList
                events={(data?.allEvents?.nodes ?? [])}
            />
        </div>
    )
};

export default UserEvents;