import { gql, useQuery } from "@apollo/client";
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Heading, Button } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

const SurveyList = () => {

    const { event_id } = useParams();
    const cookies = new Cookies();
    const navigate = useNavigate();

    const { data } = useQuery(
        gql`
            query MyQuery(
                $eventId: UUID!,
                $createdBy: UUID!
            ) {
                allSurveys(
                    filter: {
                        eventId: {
                            equalTo: $eventId
                        },
                        createdBy: {
                            equalTo: $createdBy
                        }
                    }
                ) {
                    nodes {
                        id
                        eventByEventId {
                            name
                        }
                        surveyValue
                        surveyType
                    }
                }
            }
        `,
        {
            variables: {
                eventId: event_id,
                createdBy: cookies.get('user_id')
            },
            fetchPolicy: 'cache-and-network'
        }
    )

    return (
        <div>
            <Heading
                style={{ padding: '2rem', textAlign: 'center' }}
            >
                Surveys
            </Heading>
            <div style={{ textAlign: 'center', paddingBottom: '2rem'}}>
                <Button
                    data-testid='new-survey-button'
                    onClick={() => navigate(`/events/${event_id}/settings/survey/new`)}
                    variant='solid'
                    colorScheme='blue'
                >
                    New survey
                </Button>
            </div>
            {(data?.allSurveys?.nodes ?? []).length === 0 ? (
                <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                    You did not create any survey for the moment
                </div>
            ) : (
                <TableContainer
                    data-testid='survey-event-list'
                >
                    <Table variant={'simple'}>
                        <Thead>
                            <Tr>
                                <Th>Name of event</Th>
                                <Th>Type of survey</Th>
                                <Th>Value of survey</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(data?.allSurveys?.nodes ?? []).map((survey: { id: string, surveyValue: string, surveyType: string,eventByEventId: { name: string } }, index: number) => {
                                return (
                                    <Tr
                                        key={survey.id}
                                        onClick={() => navigate(`/events/${event_id}/settings/survey/${survey.id}`)}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        data-testid={`button-survey-view-page-${index}`}
                                    >
                                        <Td>{survey.eventByEventId?.name ?? ''}</Td>
                                        <Td>{survey.surveyValue ?? ''}</Td>
                                        <Td>{survey.surveyType ?? ''}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default SurveyList;