import { gql, useQuery } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import EventList from "../../../../components/EventList";

const UserIsMember = () => {

    const navigate = useNavigate();
    const { group_id } = useParams();

    const { data } = useQuery(gql`
        query userIsMemberQuery (
            $groupId: UUID!
        ) {
            allEvents(
                filter: {
                    groupId: {
                        equalTo: $groupId
                    }
                }
                orderBy: CREATED_AT_DESC
                first: 1
            ) {
                nodes {
                    id
                    address
                    maxParticipants
                    name
                    picture
                    price
                    dateOfEvent
                    status
                }
            }
        }
    `, {
        variables: {
            groupId: group_id
        }
    });

    return (
        <div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid="settings-group-button"
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => navigate(`/groups/${group_id}/settings/`)}
                >
                    Settings
                </Button>
            </div>
            <div>
                <EventList events={(data?.allEvents?.nodes ?? [])} />
            </div>
        </div>
    )
}

export default UserIsMember;