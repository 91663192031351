import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { gql, useMutation } from '@apollo/client';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Box,
    Stack,
    Link,
    Heading,
    Text,
    useColorModeValue,
    Button,
  } from '@chakra-ui/react';

function App() {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [createOrGetUser] = useMutation(
        gql`
            mutation createOrGetUser (
                $userEmail: String!,
                $userFirstname: String!,
                $userLastname: String!
                $userPicture: String
            ) {
                createOrGetUser(
                    input: {
                        userEmail: $userEmail,
                        userFirstname: $userFirstname,
                        userLastname: $userLastname,
                        userPicture: $userPicture
                    }
                ) {
                    user {
                        email
                        id
                        firstname
                        lastname
                        token
                    }
                }
            }
        `
    )

    const [createLogin] = useMutation(
        gql`
            mutation createLogin(
                $userId: UUID!
            ) {
                createLogin(
                    input: {
                        login: {
                            userId: $userId
                        }
                    }
                ) {
                    login {
                        id
                    }
                }
            }
        `
    )

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
                <Heading fontSize={'4xl'}>Bienvenue sur sakafet.fr</Heading>
                <Text fontSize={'lg'} color={'gray.600'}>
                Faites vous inviter a des activités <Link color={'blue.400'}>par vos amis</Link> ✌️
                </Text>
            </Stack>
            <Box>
                 <GoogleLogin
                     onSuccess={async(credentialResponse) => {
                        const info: { email: string, given_name: string, family_name: string, picture: string } = jwt_decode(credentialResponse.credential ?? '');
                        const req = await createOrGetUser({
                            variables: {
                                userEmail: info?.email,
                                userFirstname: info?.given_name,
                                userLastname: info?.family_name,
                                userPicture: info?.picture
                            }
                        });
                        if (!req?.data?.createOrGetUser?.user?.firstname) {
                            navigate(`/users/${req?.data?.createOrGetUser?.user?.id}/auth/profil/email`);
                            return ;
                        }
                        cookies.set('user_id', req?.data?.createOrGetUser?.user?.id);
                        cookies.set('email', req?.data?.createOrGetUser?.user?.email);
                        cookies.set('token', req?.data?.createOrGetUser?.user?.token);
                        cookies.set('firstname', req?.data?.createOrGetUser?.user?.firstname);
                        cookies.set('lastname', req?.data?.createOrGetUser?.user?.lastname);
                        console.log(req?.data?.createOrGetUser?.user?.id);
                        await createLogin({
                            variables: {
                                userId: req?.data?.createOrGetUser?.user?.id
                            }
                        })
                        navigate('/events/');
                     }}
                 />
            </Box>
            <Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => navigate('/auth/login')}
                >
                    SignIn With Email
                </Button>
            </Box>
            <Box>
                <Button
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => navigate('/auth/register')}
                >
                    Create an account
                </Button>
            </Box>
            </Stack>
        </Flex>
    );
}

export default App;
