import { Button, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const InvitationLinkComponent = () => {
  return ( 
    <div style={{ padding: '2rem' }}>
        <Text>Copy this link and send it to invite your friends to this group</Text>
        <div style={{ display: 'flex' }}>
            <Input
                type="text"
                data-testid='group-invitation-link-input'
                defaultValue={window.location.href.replace('settings/', '')}
            />
            <Button
                onClick={() => {
                    try {
                        navigator.clipboard.writeText(window.location.href.replace('settings/', ''));
                        toast.success('URL copied');
                    } catch (e) {
                        console.error(e);
                        toast.error('Cannot copy URL, please do it manually');
                    }
                }}
            >
                Copy
            </Button>
        </div>
    </div>
  );
};

const MemberListComponent = ({
  onClick
}:{
  onClick: () => void
}) => {
  return (
    <div style={{ padding: '2rem' }}>
        <div
            style={{
                textDecoration: 'underline',
                cursor: 'pointer'
            }}
            onClick={onClick}
            data-testid='members-list-button'
        >
            See members list
        </div>
    </div>
  );
};

const InviteMemberByUsername = () => {
  
  const [userInput, setUserInput] = useState<string>('');

  const handleSubmit = () => {
    console.log(userInput);
    setUserInput('');
  };

  return (
    <div style={{ padding: '2rem' }}>
      <Text>Enter email or username of someone you want to invite to this group</Text>
      <div style={{ display: 'flex' }}>
        <Input
          type='text'
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter'){
              handleSubmit();
            }
          }}
        />
        <Button
          onClick={handleSubmit}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

const SettingGroupPage = () => {
    const navigate = useNavigate();
    const { group_id } = useParams();

    return (
        <div>
            <InvitationLinkComponent/>
            <hr/>
            <InviteMemberByUsername/>
            <hr/>
            <MemberListComponent onClick={() => navigate(`/groups/${group_id}/settings/members`)}/>
        </div>
    );
};

export default SettingGroupPage;
