const Title = ({
  value
}: {
  value: string
}) => {
  return (
    <div
      style={{
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
        marginBottom: 5
      }}
    >
      {value}
    </div>
  );
}

export default Title;
