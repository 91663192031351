import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Heading, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const EditEventPage = () => {

    const { event_id } = useParams();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const [eventName, setEventName] = useState<string>('');
    const [eventPictureURL, setEventPictureURL] = useState<string>('');
    const [eventAddress, setEventAddress] = useState<string>('');
    const [eventPrice, setEventPrice] = useState<string>('');
    const [eventMaxParticipants, setEventMaxParticipants] = useState<string>('');
    const [eventDate, setEventDate] = useState<string>('');
    const [eventTime, setEventTime] = useState<string>('');

    const { data } = useQuery(gql`
        query getEventById (
            $eventId: UUID!
        ) {
            eventById(id: $eventId) {
                address
                createdAt
                createdBy
                dateOfEvent
                id
                maxParticipants
                name
                picture
                price
            }
        }
    `, {
        variables: {
            eventId: event_id
        }
    });

    const [updateEventById] = useMutation(gql`
        mutation updateEventById(
            $address: String,
            $dateOfEvent: Datetime,
            $maxParticipants: Int,
            $name: String!,
            $picture: String,
            $price: String,
            $id: UUID!
        ) {
            updateEventById(
                input: {
                    eventPatch: {
                        address: $address,
                        dateOfEvent: $dateOfEvent,
                        maxParticipants: $maxParticipants,
                        name: $name,
                        picture: $picture,
                        price: $price
                    },
                    id: $id
                }
            ) {
                event {
                    id
                }
            }
        }

    `);

    const check_if_string_can_be_converted_to_number = (str: string) => {
        if (Number.isNaN(str)) {
            return (false);
        }
        return (true);
    };

    const check_if_day_is_correct = (str: string) => {
        const date = parseInt(str, 10);
        if (date < 0 || date > 31) {
            return (false);
        }
        return (true);
    };

    const check_if_month_is_correct = (str: string) => {
        const month = parseInt(str, 10);

        if (month < 0 || month > 12) {
            return (false);
        }
        return (true);
    };

    const check_if_year_is_correct = (str: string) => {
        const year = parseInt(str, 10);

        if (year < new Date().getFullYear()){
            return (false);
        }
        return (true);
    };

    const check_format_date = (event_date: string | undefined): boolean => {
        if (!event_date) {
            return (false);
        }
        const event_date_split = event_date.split('/');
        if (event_date_split.length !== 3) {
            return (false);
        }
        if (!check_if_string_can_be_converted_to_number(event_date_split[0]) || !check_if_day_is_correct(event_date_split[0])){
            return (false);
        }
        if (!check_if_string_can_be_converted_to_number(event_date_split[1]) || !check_if_month_is_correct(event_date_split[1])){
            return (false);
        }
        if (!check_if_string_can_be_converted_to_number(event_date_split[2]) || !check_if_year_is_correct(event_date_split[2])){
            return (false);
        }
        return (true);
    };

    const check_format_time = (event_time: string | undefined): boolean => {
        if (!event_time) {
            return (false);
        }
        const event_time_split = event_time.split(':');
        if (event_time_split.length !== 2)
            return (false);
        if (!check_if_string_can_be_converted_to_number(event_time_split[0]))
            return (false);
        if (parseInt(event_time_split[0], 10) < 0 || parseInt(event_time_split[0], 10) > 23)
            return (false);
        if (!check_if_string_can_be_converted_to_number(event_time_split[1]))
            return (false);
        if (parseInt(event_time_split[0], 10) < 0 || parseInt(event_time_split[0], 10) > 60)
            return (false);
        return (true);
    };

    const getDateFromInput = (event_date: string = '', event_time: string = '') => {
        if (!event_date || !eventTime)
            return ('');
        const event_date_split = event_date.split('/');
        const event_time_split = event_time.split(':');

        const year = parseInt(event_date_split[2], 10);
        const month = parseInt(event_date_split[1], 10) - 1;
        const day = parseInt(event_date_split[0], 10);

        const minute = parseInt(event_time_split[0], 10) + 1;
        const seconds = parseInt(event_time_split[1], 10);

        return new Date(
            year,
            month,
            day,
            minute,
            seconds
        );
    };

    const datetime_to_date = (datetime: string) => {
        if (!datetime) {
            return ('');
        }
        const date_split = datetime.split('T')[0].split('-');

        const year = date_split[0];
        const month = date_split[1];
        const day = date_split[2];

        return (`${day}/${month}/${year}`);
    };

    const datetime_to_time = (datetime: string) => {
        if (!datetime) {
            return ('');
        }
        const time_split = datetime.split('T')[1].split(':');

        const hours = time_split[0];
        const minutes = time_split[1];

        return (`${hours}:${minutes}`);
    };

    const set_default_values = () => {
        if (data) {
            setEventName(data.eventById?.name);
            setEventPictureURL(data.eventById?.picture);
            setEventAddress(data.eventById?.address);
            setEventPrice(data.eventById?.price);
            setEventMaxParticipants(data.eventById?.maxParticipants);
            setEventDate(datetime_to_date(data.eventById?.dateOfEvent));
            setEventTime(datetime_to_time(data.eventById?.dateOfEvent));
        }
    };

    useEffect(() => {
        set_default_values();
    }, [data]);

    return (
        <div
            style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}
        >
            <div style={{ textAlign: 'center' }}>
                <Heading>
                    New event creation
            </Heading>
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Name of the event*</Text>
                <Input
                    placeholder="Tennis at Reuilly"
                    value={eventName}
                    onChange={(e: { target: { value: string }}) => setEventName(e.target.value)}
                    data-testid="input-event-name"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Picture URL</Text>
                <Input
                    placeholder="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
                    value={eventPictureURL}
                    onChange={(e: { target: { value: string }}) => setEventPictureURL(e.target.value)}
                    data-testid="input-event-picture"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Address*</Text>
                <Input
                    placeholder="7 boulevard de reuilly, 75012 Paris"
                    value={eventAddress}
                    onChange={(e: { target: { value: string }}) => setEventAddress(e.target.value)}
                    data-testid="input-event-address"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Price (without the devise)</Text>
                <Input
                    placeholder="3"
                    value={eventPrice}
                    onChange={(e: { target: { value: string }}) => setEventPrice(e.target.value)}
                    data-testid="input-event-price"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Max participants</Text>
                <Input
                    placeholder="4"
                    value={eventMaxParticipants}
                    onChange={(e: { target: { value: string }}) => setEventMaxParticipants(e.target.value)}
                    data-testid="input-event-max-participants"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Date of event (Format DD/MM/YYYY)</Text>
                <Input
                    placeholder="24/05/2023"
                    value={eventDate}
                    onChange={(e: { target: { value: string }}) => setEventDate(e.target.value)}
                    data-testid="input-event-date"
                />
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Time of event (Format HH:MM)</Text>
                <Input
                    placeholder="14:30"
                    value={eventTime}
                    onChange={(e: { target: { value: string }}) => setEventTime(e.target.value)}
                    data-testid="input-event-time"
                />
            </div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid="submit-button-update-event"
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        try {

                            if (eventPrice && Number.isNaN(parseInt(eventPrice ?? '', 10))){
                                toast.error('Price should be a number');
                                return ;
                            }
                            if (eventMaxParticipants && Number.isNaN(parseInt(eventMaxParticipants ?? '', 10))){
                                toast.error('Max number of participants should be a number');
                                return ;
                            }
                            if (eventDate && !check_format_date(eventDate)) {
                                toast.error('Date format is incorrect');
                                return ;
                            }
                            if (eventTime && !check_format_time(eventTime)) {
                                toast.error('time format is incorrect');
                                return ;
                            }
                            if (eventAddress && !eventAddress) {
                                toast.error('Adress cannot be empty');
                                return ;
                            }
                            if (eventName && !eventName) {
                                toast.error('Name cannot be empty');
                                return ;
                            }

                            await updateEventById({
                                variables: {
                                    name: eventName,
                                    address: eventAddress ?? null,
                                    price: eventPrice ?? null,
                                    dateOfEvent: (eventDate && eventTime) ? getDateFromInput(eventDate, eventTime) : null,
                                    createdBy: cookies.get('user_id') ?? '',
                                    picture: eventPictureURL ?? null,
                                    maxParticipants: parseInt(eventMaxParticipants ?? '0', 10) ?? null,
                                    id: event_id,
                                }
                            });
                            toast.success('Event updated');
                            navigate(`/events/${event_id}/`);
                        } catch (e) {
                            console.error(e);
                            toast.error('Error while updating event');
                        }
                    }}
                >
                    Validate
                </Button>
            </div>
        </div>
    );
};

export default EditEventPage;