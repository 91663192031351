import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Input, FormLabel } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { surveyTimelineEnum } from "../../../enums/surveyTimelineEnum";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const SurveyVotePage = () => {

    const { survey_id, event_id } = useParams();
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [userAnswer, setUserAnswer] = useState<any>();
    const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
    const [inputFirstname, setInputFirstname] = useState<string>("");

    const { data: surveyInfo } = useQuery(gql`
        query surveyById(
            $surveyId: UUID!
        ) {
          surveyById(id: $surveyId) {
              surveyType
              surveyValue
              createdAt
          }
        }
    `, {
      variables: {
          surveyId: survey_id,
          userId: cookies.get('user_id')
      },
      fetchPolicy: 'cache-and-network'
    });

    const { data } = useQuery(gql`
        query allSurveyAnswers(
            $surveyId: UUID!
            $userId: UUID!
        ) {
          allSurveyAnswers(
              filter: {
                  surveyId: {
                      equalTo: $surveyId,
                  }
                  userId: {
                      equalTo: $userId
                  }
              }
          ) {
              nodes {
                  id
                  answer
              }
          }
        }
    `, {
      variables: {
          surveyId: survey_id,
          userId: cookies.get('user_id')
      },
      fetchPolicy: 'cache-and-network'
    });

    const [createSurveyAnswer] = useMutation(gql`
        mutation createSurveyAnswer (
            $surveyId: UUID!,
            $answer: String!,
            $userId: UUID,
            $firstname: String,
        ) {
            createSurveyAnswer(
                input: {
                    surveyAnswer: {
                        surveyId: $surveyId,
                        answer: $answer,
                        userId: $userId,
                        firstname: $firstname,
                    }
                }
            ) {
                surveyAnswer {
                    id
                }
            }
        }
    `);

    const [updateSurveyAnswerById] = useMutation(gql`
        mutation updateSurveyAnswerById(
            $surveyAnswerId: UUID!,
            $answer: String!
        ) {
            updateSurveyAnswerById(
                input: {
                    surveyAnswerPatch: {
                        answer: $answer
                    },
                    id: $surveyAnswerId
                }
            ) {
                surveyAnswer {
                    id
                }
            }
        }
    `)

    const get_number_of_days_for_survey = (surveyValue: string): number => {
        switch (surveyValue) {
            case surveyTimelineEnum.SEVEN_DAYS:
                return (7);
            case surveyTimelineEnum.FOURTEEN_DAYS:
                return (14);
            case surveyTimelineEnum.THIRTY_DAYS:
                return (30);
            default:
                return (0);
        }
    };

    const format_date_for_survey = (createdAt: any, day_number: number) => {
        const dt = new Date(createdAt);

        dt.setDate(dt.getDate() + day_number);

        return (dt.toDateString());
    };

    useEffect(() => {
        if (!data && !surveyInfo) {
            return;
        }
        setUserAnswer(
            (data?.allSurveyAnswers?.nodes ?? []).length > 0
                ? ((data?.allSurveyAnswers?.nodes[0].answer.split(',')))
                : (
                    Array.from(
                        Array(
                            get_number_of_days_for_survey(surveyInfo?.surveyById?.surveyValue)
                        )
                    ).fill(false)
                )
        );
    }, [data, surveyInfo]);

    const [checkToken] = useMutation(gql`
        mutation checkToken (
            $userToken: UUID
        ) {
            checkToken(
                input: {
                    appUserToken: $userToken
                }
            ) {
                boolean
            }
        }
    `)

    const check_user_logged = async() => {
        try {
            await checkToken({
                variables: {
                    userToken: cookies.get('token')
                }
            });
            setIsUserLogged(true);
        } catch (e) {
            setIsUserLogged(false);
        }
    };


    useEffect(() => {
        check_user_logged();
    }, [false]);

    return (
        <div>
            <TableContainer style={{ paddingTop: '2rem' }}>
                {!isUserLogged &&
                  <div>
                    <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '2rem' }}>
                      <FormLabel>What is your name ?</FormLabel>
                      <Input value={inputFirstname} onChange={(e) => setInputFirstname(e.target.value)}/>
                    </div>
                    <hr style={{ paddingBottom: '1rem' }}/>
                  </div>
                }
                <FormLabel style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>Please enter when you will be available:</FormLabel>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Date</Th>
                            <Th>Are you available ?</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(
                            Array.from(
                                Array(
                                    get_number_of_days_for_survey(
                                        surveyInfo?.surveyById?.surveyValue
                                    )
                                ).keys()
                            )
                        ).map((day_number) => {
                            return (
                                <Tr key={day_number}>
                                    <Td>{format_date_for_survey(surveyInfo?.surveyById?.createdAt, day_number)}</Td>
                                    <Td>
                                        <Checkbox
                                            data-testid={`checkbox-vote-${day_number}`}
                                            defaultChecked={
                                                (data?.allSurveyAnswers?.nodes ?? []).length > 0
                                                    ? JSON.parse((data?.allSurveyAnswers?.nodes[0].answer.split(',')[day_number]))
                                                    : false
                                            }
                                            onChange={(e) => {
                                                const output = userAnswer;
                                                output[day_number] = e.target?.checked
                                                setUserAnswer(output);
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <div style={{ textAlign: 'center', paddingTop: '3rem' }}>
                <Button
                    data-testid='button-save-survey'
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        try {
                            ((data?.allSurveyAnswers?.nodes ?? []).length > 0) ? (
                                await updateSurveyAnswerById({
                                    variables: {
                                        surveyAnswerId: data?.allSurveyAnswers?.nodes[0].id,
                                        answer: userAnswer.toString(),
                                    }
                                })
                            ) : (
                                await createSurveyAnswer({
                                    variables: {
                                        surveyId: survey_id,
                                        answer: userAnswer.toString(),
                                        userId: cookies.get('user_id') ?? null,
                                        firstname: inputFirstname ?? null,
                                    }
                                })
                            );
                            toast.success('You\'re answer was correctly saved');
                            navigate(`/events/${event_id}/`);
                        } catch (e) {
                            console.error(e);
                            toast.error('There was an error while saving your answer, please try again later')
                        }
                    }}
                >
                    Send answer
                </Button>
            </div>
        </div>
    );
};

export default SurveyVotePage;
