import {ReactNode} from "react";
import {IUser} from "../types/IUser";
import {Avatar, Box, Flex, Text} from "@chakra-ui/react";
import {formatRelative, parseISO, format, isSameDay} from "date-fns";

export type QuoteProps = {
    children: ReactNode,
    user: IUser,
    date: string,
    fromConnectedUser: boolean,
    inGroup: 'alone' | 'first' | 'last' | 'middle',
}

const radius = 20
const smallRadius = 3

export function Quote({children, user, date, fromConnectedUser, inGroup}: QuoteProps) {
    const dateObject = parseISO(date);
    const displayDate = isSameDay(dateObject, new Date())
        ? format(dateObject, 'HH:mm')
        : formatRelative(dateObject, new Date());

    return <Flex alignItems="flex-end">
        {(inGroup === 'last' || inGroup === 'alone') && !fromConnectedUser &&
            <Avatar src={user.picture}
                    name={`${user.firstname} ${user.lastname}`}
                    marginRight="1"
                    showBorder/>}
        <Box backgroundColor={fromConnectedUser ? 'blue.400' : 'blue.600'}
             color="white"
             borderBottomLeftRadius={!fromConnectedUser && (inGroup === 'first' || inGroup === 'middle') ? smallRadius : radius}
             borderBottomRightRadius={fromConnectedUser && (inGroup === 'first' || inGroup === 'middle') ? smallRadius : radius}
             borderTopLeftRadius={!fromConnectedUser && (inGroup === 'last' || inGroup === 'middle') ? smallRadius : radius}
             borderTopRightRadius={fromConnectedUser && (inGroup === 'last' || inGroup === 'middle') ? smallRadius : radius}
             padding="2"
             marginLeft={!fromConnectedUser && (inGroup === 'first' || inGroup === 'middle') ? 51 : 0}
        >
            {(inGroup === 'first' || inGroup === 'alone') && <Text fontSize="xs">{user.firstname}</Text>}
            <Text marginTop="1" marginBottom="1">{children}</Text>
            <Text fontSize="xs" align='right'>{displayDate}</Text>
        </Box>
    </Flex>
}
