const Link = ({
  value,
  url
}: {
  value: string,
  url: () => void
}) => {
  return (
    <span
      style={{
        color: '#A162F7',
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
        cursor: 'pointer'
      }}
      onClick={url}
    >
      {value}
    </span>
  );
};

export default Link;
