import { gql, useMutation } from "@apollo/client";
import { Button, Heading, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const EventNewPage = () => {

    const [eventName, setEventName] = useState<string>();

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [createEvent] = useMutation(gql`
        mutation createEvent(
            $name: String!,
            $createdBy: UUID!,
        ) {
            createEvent(
                input: {
                    event: {
                        name: $name,
                        createdBy: $createdBy
                    }
                }
            ) {
                event {
                    id
                }
            }
        }
    `);

    return (
        <div
            style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}
        >
            <div style={{ textAlign: 'center' }}>
                <Heading>
                    New event creation
                </Heading>
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Name of the event*</Text>
                <Input
                    placeholder="Tennis at Reuilly"
                    value={eventName}
                    onChange={(e: { target: { value: string }}) => setEventName(e.target.value)}
                    data-testid="input-event-name"
                />
            </div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid="submit-button-new-event"
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        try {
                            if (!eventName) {
                                toast.error('Name cannot be empty');
                                return ;
                            }

                            const req = await createEvent({
                                variables: {
                                    name: eventName,
                                    createdBy: cookies.get('user_id') ?? '',
                                }
                            });
                            toast.success('Event created');
                            navigate(`/events/${req.data?.createEvent?.event?.id}/`);
                        } catch (e) {
                            console.error(e);
                            toast.error('Error while creating event');
                        }
                    }}
                >
                    Validate
                </Button>
            </div>
        </div>
    );
};

export default EventNewPage;