import { Card, Image, CardBody, Button, Text, Heading, Tag } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface IEvent {
    id: string,
    address: string,
    maxParticipants: string,
    name: string,
    picture: string,
    price: string,
    dateOfEvent: string,
    status: string,
}

const EventList = (props: { events: IEvent[] }) => {

    const navigate = useNavigate();

    const getTimeCorrect = (data: string) => {
        if (!data) {
            return ('');
        }
        const date = data.split('T')[0].split('-');
        const minutes = data.split('T')[1].split('.')[0].split(':');

        return (`Le ${date[2]}/${date[1]}/${date[0]} à ${minutes[0]}:${minutes[1]}`);
    }

    return (
        <div style={{ marginLeft: '15%', marginRight: '15%' }} data-testid='event-list'>
            {(props.events ?? []).map((event: IEvent, index: number) => {
                return(
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        style={{ marginTop: 50 }}
                        key={event.id}
                    >
                        <Image
                            objectFit='cover'
                            maxW={{ base: '100%', sm: '300px' }}
                            src={event?.picture}
                            alt={event.name}
                        />
                        <CardBody style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Heading size='md'>
                                {event.name}
                                &nbsp;
                                <Tag
                                    variant='solid' colorScheme={event.status === 'DRAFT' ? 'teal' : 'red'}
                                >
                                    {event.status}
                                </Tag>
                            </Heading>
                            <Text py='2'>{getTimeCorrect(event.dateOfEvent)}</Text>
                            <Text py='2'>{event.address}</Text>
                            <div>
                                <Button
                                    variant='solid'
                                    colorScheme='blue'
                                    onClick={() => navigate(`/events/${event.id}/`)}
                                    data-testid={`button-view-event-${index}`}
                                >
                                    Voir plus
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                )
            })}
        </div>
    )
};

export default EventList;