import { gql, useQuery } from "@apollo/client";
import { Button, Input, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { surveyTimelineEnum } from "../../../../enums/surveyTimelineEnum";

const ViewSurveyPage = () => {

    const { event_id, survey_id } = useParams();
    const navigate = useNavigate();

    const { data } = useQuery(gql`
        query getSurveyInfos(
            $surveyId: UUID!
        ) {
            surveyById(id: $surveyId) {
                surveyType
                surveyValue
                createdAt
                surveyAnswersBySurveyId {
                    nodes {
                        answer
                        firstname
                        userByUserId {
                            firstname
                            lastname
                            email
                        }
                    }
                }
            }
        }
    `, {
        variables: {
            surveyId: survey_id
        },
        fetchPolicy: 'cache-and-network'
    });

    const getSurveyVoteLink = (): string => {
        const uri: string = (window.location.hostname === 'localhost'
            ? 'http://localhost:3000'
            : 'https://sakafet.fr'
        );
        return (`${uri}/events/${event_id}/survey/${survey_id}/vote`);
    };

    const get_number_of_days_for_survey = (surveyValue: string): number => {
        switch (surveyValue) {
            case surveyTimelineEnum.SEVEN_DAYS:
                return (7);
            case surveyTimelineEnum.FOURTEEN_DAYS:
                return (14);
            case surveyTimelineEnum.THIRTY_DAYS:
                return (30);
            default:
                return (0);
        }
    };

    const format_date_for_survey = (createdAt: any, day_number: number) => {
        const dt = new Date(createdAt);

        dt.setDate(dt.getDate() + day_number);

        return (dt.toDateString());
    };

    return (
        <div
            style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
        >
            <div style={{ paddingTop: '2rem', paddingBottom: '2rem'}}>
                <p>Here is the link for your friends to vote for the survey:</p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        paddingTop: '1rem'
                    }}
                >
                    <Input value={getSurveyVoteLink()}/>
                    <Button
                        variant='solid'
                        colorScheme='blue'
                        onClick={() => {
                            try {
                                navigator.clipboard.writeText(getSurveyVoteLink());
                                toast.success('Link copied!');
                            } catch(e) {
                                toast.error('Impossible to copy link, please do it manually');
                            }
                        }}
                    >
                        Copy
                    </Button>
                </div>
            </div>
            <hr/>
            <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Result of the survey:</p>
                    <Button
                        variant='solid'
                        colorScheme='blue'
                        onClick={() => navigate(`/events/${event_id}/survey/${survey_id}/vote`)}
                        data-testid='survey-button-vote'
                    >
                        Vote
                    </Button>
                </div>
                <div data-testid='table-list-result-survey'>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    {(data?.surveyById?.surveyAnswersBySurveyId?.nodes ?? []).map((surveyAnswer: { answer: string, userByUserId?: { firstname: string, email: string }, firstname?: string }) => {
                                        return (
                                            <Th>{surveyAnswer?.userByUserId?.firstname ?? surveyAnswer?.firstname} {surveyAnswer?.userByUserId?.email ? `(${surveyAnswer?.userByUserId?.email})` : ""}</Th>
                                        )
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {(
                                    Array.from(
                                        Array(
                                            get_number_of_days_for_survey(
                                                data?.surveyById?.surveyValue
                                            )
                                        ).keys()
                                    )
                                ).map((day_number) => {
                                    return (
                                        <Tr key={day_number}>
                                            <Td>{format_date_for_survey(data?.surveyById?.createdAt, day_number)}</Td>
                                            {(data?.surveyById?.surveyAnswersBySurveyId?.nodes ?? []).map((surveyAnswer: { answer: string, userByUserId?: { firstname: string }, firstname?: string }) => {
                                                return (
                                                    <Td>{JSON.parse(surveyAnswer?.answer.split(',')[day_number]) && 'available'}</Td>
                                                )
                                            })}
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
};

export default ViewSurveyPage;
