import { useNavigate, useParams } from 'react-router';
import { useQuery, gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Tag } from '@chakra-ui/react';

const event_status_enum = {
    DRAFT: 'DRAFT',
    CONFIRMED: 'CONFIRMED'
};

const SettingsEventPage = () => {
    const navigate = useNavigate();
    const { event_id } = useParams();

    const { data, refetch } = useQuery(
        gql`
            query eventSettingsPage(
                $eventId: UUID!
            ) {
                eventById (id: $eventId) {
                    name
                    status
                    dateOfEvent
                    address
                }
            }
        `,
        {
            variables: {
                eventId: event_id
            }
        }
    );

    const [updateEventStatus] = useMutation(
        gql`
            mutation MyMutation(
                $status: EventStatus!,
                $id: UUID!,
                $confirmedAt: Datetime!
            ) {
                updateEventById(
                    input: {
                        eventPatch: {
                            status: $status,
                            confirmedAt: $confirmedAt
                        },
                        id: $id
                    }
                ) { 
                    event {
                        id
                    }
                }
            }
        `
    );

    const getEnFormatDate = () => {
        const datetime = new Date().toISOString();
        const [date, hours] = datetime.split('T');
        const date_split = date.split('-');
        const hours_split = hours.split(':');

        const year = date_split[0].padStart(2, '0');
        const month = date_split[1].padStart(2, '0');
        const day = date_split[2].padStart(2, '0');

        const minutes = hours_split[0].padStart(2, '0');
        const secondes = hours_split[1].padStart(2, '0');
        return (`${year}-${month}-${day} ${minutes}:${secondes}`);
    };

    return (
        <div
            style={{ marginLeft: '5rem', marginTop: '2.5rem' }}
        >
            <p
                style={{ marginBottom: '1.5rem' }}
            >
                Your are into the setting of the event { data?.eventById?.name }
            </p>
            <p
                style={{ marginBottom: '1.5rem' }}
            >
                This event is currently in status:&nbsp;
                <Tag
                    variant='solid'
                    colorScheme={data?.eventById.status === 'DRAFT' ? 'teal' : 'red'}
                    data-testid='event-settings-status'
                >
                    {data?.eventById?.status}
                </Tag>
            </p>
            <ul>
                <li
                    onClick={() => navigate(`/events/${event_id}/settings/survey/`)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    data-testid='survey-event'
                >
                    Surveys
                </li>
                <li
                    onClick={() => navigate(`/events/${event_id}/settings/founding/`)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    data-testid='event-founding-button'
                >
                    Founding
                </li>
                <li
                    onClick={() => navigate(`/events/${event_id}/settings/edit/`)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    data-testid='edit-event'
                >
                    Edit event
                </li>
                <li
                    onClick={() => navigate(`/events/${event_id}/`)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    data-testid='view-event'
                >
                    View event
                </li>
                <li
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    data-testid='confirm-event'
                    onClick={async() => {
                        try {
                            if (!data?.eventById?.dateOfEvent){
                                toast.error('Your event must have a date to be confirmed');
                                return ;
                            }
                            if (!data?.eventById?.address){
                                toast.error('Your event must have an address to be confirmed');
                                return ;
                            }
                            await updateEventStatus({
                                variables: {
                                    status: (data?.eventById?.status === event_status_enum.CONFIRMED)
                                        ? event_status_enum.DRAFT
                                        : event_status_enum.CONFIRMED,
                                    confirmedAt: getEnFormatDate(),
                                    id: event_id
                                }
                            });
                            refetch({
                                eventId: event_id
                            });
                            toast.success('Your event is now confirmed!');
                        } catch (e) {
                            console.error(e);
                            toast.error("Error while confirming event");
                        }
                    }}
                >
                    {data?.eventById?.status === event_status_enum.CONFIRMED ? 'Set back to draft' : 'Confirm'} this event
                </li>
            </ul>
        </div>
    )
};

export default SettingsEventPage;
