import { gql, useQuery } from "@apollo/client";
import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const GroupListPage = () => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    const { data } = useQuery(gql`
        query GroupListPage (
            $userId: UUID!
        ) {
            allGroupMembers(
                filter: {
                    userId: {
                        equalTo: $userId
                    }
                }
            ) {
                nodes {
                    groupByGroupId {
                        name
                        id
                        groupMembersByGroupId {
                            totalCount
                        }
                    }
                }
            }
        }
    `, {
        variables: {
            userId: cookies.get('user_id')
        },
        fetchPolicy: 'cache-and-network'
    });

    return (
        <div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid="create-new-group-button"
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => navigate('/groups/new/')}
                >
                    Create new group
                </Button>
            </div>
            {(data?.allGroupMembers?.nodes ?? []).length === 0 ? (
                <div style={{ padding: '2rem' }}>You are not part of any group for the moment</div>
            ) : (
                <TableContainer>
                    <Table
                        data-testid='group-list-table'
                        variant='simple'
                    >
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Members</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                                {(data?.allGroupMembers?.nodes ?? []).map((group: { groupByGroupId: { name: string, id: string, groupMembersByGroupId: { totalCount: number } } }) => (
                                    <Tr
                                        key={group?.groupByGroupId?.id}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={async() => navigate(`/groups/${group?.groupByGroupId?.id}/`)}
                                        data-testid={`groups-${group?.groupByGroupId?.id}`}
                                    >
                                        <Td>{group?.groupByGroupId?.name}</Td>
                                        <Td>{group?.groupByGroupId?.groupMembersByGroupId?.totalCount}</Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
};

export default GroupListPage;