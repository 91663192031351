import { gql, useMutation, useQuery } from "@apollo/client";
import { Heading, Image, Flex, Avatar, Box, Button, RadioGroup, Stack, Radio, Tooltip, Tag } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { EditIcon } from "@chakra-ui/icons";
import { FoundingStatusEnum } from "../../../enums/FoundingStatusEnum";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import {
  getDateFromDatetime,
  getTimeFromDatetime,
  getTimeCorrect,
  addHoursToDate
} from "../../../utils/date";

interface IUsers {
    id: string,
    email: string,
    firstname: string,
    lastname: string,
    picture: string,
}

interface IParticipation {
    id: string,
    eventId: string,
    participationType: string,
    userId: string,
    userByUserId: IUsers,
};

const EventViewPage = () => {

    const params = useParams();
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [participationValue, setParticipationValue] = useState<string>('');
    const [userIsLogged, setUserIsLogged] = useState<boolean>(false);

    const [createEventParticipation] = useMutation(gql`
        mutation createEventParticipation (
            $userId: UUID!,
            $eventId: UUID!,
            $participationType: EventParticipationsEnum
        ) {
            createEventParticipation(
                input: {
                    eventParticipation: {
                        userId: $userId,
                        eventId: $eventId,
                        participationType: $participationType
                    }
                }
            ) {
                eventParticipation {
                    eventId
                    userId
                    id
                }
            }
        }
    `)

    const [updateEventParticipationById] = useMutation(gql`
        mutation updateEventParticipationById (
            $eventParticipationId: UUID!,
            $participationType: EventParticipationsEnum
        ) {
            updateEventParticipationById(
                input: {
                    id: $eventParticipationId,
                    eventParticipationPatch: {
                        participationType: $participationType
                    }
                }
            ) {
                eventParticipation {
                    eventId
                    userId
                    id
                }
            }
        }
    `)

    const [checkToken] = useMutation(gql`
        mutation checkToken (
            $userToken: UUID
        ) {
            checkToken(
                input: {
                    appUserToken: $userToken
                }
            ) {
                boolean
            }
        }
    `)

    const [createOrGetUser] = useMutation(
        gql`
            mutation createOrGetUser (
                $userEmail: String!,
                $userFirstname: String!,
                $userLastname: String!
                $userPicture: String
            ) {
                createOrGetUser(
                    input: {
                        userEmail: $userEmail,
                        userFirstname: $userFirstname,
                        userLastname: $userLastname,
                        userPicture: $userPicture
                    }
                ) {
                    user {
                        email
                        id
                        firstname
                        lastname
                        token
                    }
                }
            }
        `
    )

    const { data, loading } = useQuery(gql`
        query getEventById (
            $eventId: UUID!,
            $eventParticipationType: EventParticipationsEnum = NO
        ) {
            eventById(id: $eventId) {
                address
                createdAt
                createdBy
                dateOfEvent
                id
                maxParticipants
                name
                picture
                price
                status
                confirmedAt
                foundingByEventId {
                    price
                    status
                }
                surveysByEventId(
                    filter: {
                        surveyType: {
                            equalTo: DATE
                        }
                    },
                    first: 1
                ) {
                    nodes {
                        id
                    }
                }
            }
            allEventParticipations(
                filter: {
                    participationType: {
                        notEqualTo: $eventParticipationType
                    },
                    eventId: {
                        equalTo: $eventId
                    }
                }
            ) {
                nodes {
                    eventId
                    id
                    participationType
                    userId
                    userByUserId {
                        id
                        email
                        firstname
                        lastname
                        picture
                    }
                }
                totalCount
            }
        }
    `, {
        variables: {
            eventId: params?.event_id ?? ''
        },
        fetchPolicy: 'cache-and-network'
    });

    const isParticipating = () => {
        return (
            (data?.allEventParticipations?.nodes ?? []).find((participation: IParticipation) => participation.userId === cookies.get('user_id')) ?? false
        );
    }

    const check_user_logged = async() => {
        try {
            await checkToken({
                variables: {
                    userToken: cookies.get('token')
                }
            });
            setUserIsLogged(true);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        check_user_logged();
    }, [false]);

    useEffect(() => {
        setParticipationValue(isParticipating()?.participationType ?? '')
    }, [data]);

    if (loading){
        return <div>Loading...</div>
    }

    return (
        <div style={{ marginLeft: '20%', marginRight: '20%' }}>
            <div>
                <Heading
                    style={{ textAlign: 'center', marginBottom: 5, marginTop: 25 }}
                >
                    <div
                        data-testid="event-view-name"
                    >
                        {data.eventById.name}
                    </div>
                    &nbsp;
                    {data.eventById?.createdBy === cookies.get('user_id') &&
                        <Tooltip
                            label='Edit this event'
                        >
                            <EditIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/events/${params?.event_id}/settings/`)}
                                data-testid='event-settings-icon'
                            />
                        </Tooltip>
                    }
                </Heading>
                <div
                    style={{ textAlign: 'center' }}
                >
                    <Tag
                        variant='solid'
                        colorScheme={data?.eventById?.status === 'DRAFT' ? 'teal' : 'red'}
                        data-testid='event-view-status'
                    >
                        {data?.eventById?.status}
                    </Tag>
                </div>
                <p
                    style={{ textAlign: 'center', marginBottom: 50, fontStyle: 'italic' }}
                    data-testid="event-view-address"
                >
                    {data.eventById.address}
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 25 }}>
                    <p
                        style={{ textAlign: 'center', marginBottom: 25 }}
                        data-testid="event-view-date"
                    >
                        {getTimeCorrect(data.eventById.dateOfEvent)}
                    </p>
                    {data.eventById.price &&
                        <p
                            style={{ textAlign: 'center', marginBottom: 25 }}
                            data-testid="event-view-price"
                        >
                            {data.eventById.price}€
                        </p>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '1rem' }}>
                  <AddToCalendarButton
                    name={data.eventById.name}
                    options={['Apple','Google']}
                    location={data.eventById.address}
                    startDate={getDateFromDatetime(data.eventById.dateOfEvent)}
                    startTime={getTimeFromDatetime(data.eventById.dateOfEvent)}
                    endTime={getTimeFromDatetime(
                      addHoursToDate(data.eventById.dateOfEvent, 1)
                    )}
                    timeZone="Europe/Paris"
                  ></AddToCalendarButton>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image
                        src={data.eventById.picture}
                        alt={data.eventById.name}
                        maxW={{ base: '100%', sm: '500px' }}
                    />
                </div>
            </div>
            {(
                userIsLogged &&
                isParticipating() &&
                (data?.eventById?.surveysByEventId?.nodes ?? []).length > 0 &&
                !data?.eventById?.confirmedAt
            ) &&
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => navigate(`/events/${data?.eventById?.id}/survey/${data?.eventById?.surveysByEventId?.nodes[0].id}/vote`)}
                >
                    Vote for date of event here
                </div>
            }
            { userIsLogged ? (
                <div>
                    <p style={{ textAlign: 'center', marginTop: 10}}>Participez-vous ?</p>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10}}>
                        <RadioGroup
                            onChange={setParticipationValue}
                            value={participationValue}
                            defaultValue='YES'
                        >
                            <Stack direction='row'>
                                <Radio value='YES'>Je participe</Radio>
                                <Radio value='INTERESTED'>Je suis juste interessé</Radio>
                                <Radio value='NO'>Je ne participe pas</Radio>
                            </Stack>
                        </RadioGroup>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10}}>
                        <Button variant='solid' colorScheme='blue'
                            data-testid='button-participation-event'
                            onClick={async() => {
                                if (!participationValue){
                                    toast.error('Vous devez  pas choisis une option');
                                    return ;
                                }
                                const participation: IParticipation = isParticipating();
                                if (participation){
                                    await updateEventParticipationById({
                                        variables: {
                                            eventParticipationId: participation.id,
                                            participationType: participationValue
                                        },
                                        refetchQueries: ['getEventById']
                                    });
                                } else {
                                    await createEventParticipation({
                                        variables: {
                                            userId: cookies.get('user_id'),
                                            eventId: params.event_id,
                                            participationType: participationValue
                                        },
                                        refetchQueries: ['getEventById']
                                    });
                                }
                                toast.success('Votre choix a bien été pris en compte');
                            }}
                        >
                            {data?.eventById?.foundingByEventId?.status === FoundingStatusEnum.ACTIVATED ? (
                                `Save (${data?.eventById?.foundingByEventId?.price}€)`
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                    {(data.allEventParticipations?.nodes ?? []).lenght > 0 &&
                        <Fragment>
                            <hr/>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 25 }}>
                                <p style={{ textAlign: 'center', marginBottom: 10 }}>Participants:</p>
                            </div>
                        </Fragment>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
                        <div>
                            {(data.allEventParticipations?.nodes ?? []).filter((participations: IParticipation) => participations.participationType !== 'NO').map((participations: IParticipation) => {
                                return (
                                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' style={{ marginBottom: 10 }} key={participations.id}>
                                        <Avatar name={`${participations.userByUserId.firstname} ${participations.userByUserId.lastname}`} src={participations.userByUserId.picture} />
                                        <Box>
                                            <Heading size='sm'>{participations.userByUserId.firstname} {participations.userByUserId.lastname}</Heading>
                                            <div>{participations.participationType === 'INTERESTED' && 'Interested'}</div>
                                        </Box>
                                    </Flex>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <Flex
                        minH={'100vh'}
                        justify={'center'}
                    >
                        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                        <Stack align={'center'}>
                            <p>Il y a {data?.allEventParticipations?.totalCount} participants pour le moment</p>
                            <p>Veuillez vous connectez pour voir la liste des participants et/ou vous inscrire a l'evenement</p>
                        </Stack>
                        <Box>
                            <GoogleLogin
                                onSuccess={async(credentialResponse) => {
                                    const info: { email: string, given_name: string, family_name: string, picture: string } = jwt_decode(credentialResponse.credential ?? '');
                                    const req = await createOrGetUser({
                                        variables: {
                                            userEmail: info?.email,
                                            userFirstname: info?.given_name,
                                            userLastname: info?.family_name,
                                            userPicture: info?.picture
                                        }
                                    });
                                    cookies.set('user_id', req?.data?.createOrGetUser?.user?.id);
                                    cookies.set('email', req?.data?.createOrGetUser?.user?.email);
                                    cookies.set('token', req?.data?.createOrGetUser?.user?.token);
                                    cookies.set('firstname', req?.data?.createOrGetUser?.user?.firstname);
                                    cookies.set('lastname', req?.data?.createOrGetUser?.user?.lastname);
                                    setUserIsLogged(true);
                                }}
                            />
                        </Box>
                        </Stack>
                    </Flex>
                </div>
            )}
        </div>
    );
};

export default EventViewPage;
