import { Button } from "@chakra-ui/react";
import { useParams } from "react-router";
import { useMutation, gql } from "@apollo/client";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const UserIsLoggedButNotMember = (props: { refetch: any }) => {
    const { group_id } = useParams();
    const cookies = new Cookies();

    const [createGroupMember] = useMutation(gql`
        mutation createGroupMember(
            $groupId: UUID!,
            $userId: UUID!
        ) {
            createGroupMember(
                input: {
                    groupMember: {
                        groupId: $groupId,
                        userId: $userId
                    }
                }
            ) {
                groupByGroupId {
                    id
                }
            }
        }
    `);

    return (
        <div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid="settings-group-button"
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        try {
                            await createGroupMember({
                                variables: {
                                    groupId: group_id,
                                    userId: cookies.get('user_id')
                                }
                            });
                            toast.success('Succefully joined group');
                            props.refetch();
                        } catch (e) {
                            console.error(e);
                            toast.error('Error while trying to join group, please try again later');
                        }
                    }}
                >
                    Join goup
                </Button>
            </div>
        </div>
    )
}

export default UserIsLoggedButNotMember;
