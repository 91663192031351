import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const GroupMembersPage = () => {

    const { group_id } = useParams();

    const { data } = useQuery(gql`
        query groupMemberPage (
            $groupId: UUID!
        ) {
            allGroupMembers (
                filter: {
                    groupId: {
                        equalTo: $groupId
                    }
                }
            ) {
                nodes {
                    userByUserId {
                        firstname
                        email
                    }
                    id
                }
                totalCount
            }
        }
    `, {
        variables: {
            groupId: group_id
        }
    });

    return (
        <div
            style={{ padding: '2rem' }}
        >
            <div
                data-testid='members-count'
                style={{ paddingBottom: '1rem' }}
            >
                { data?.allGroupMembers.totalCount } member{ parseInt(data?.allGroupMembers.totalCount, 10) > 1 && 's' }
            </div>
            <div
                data-testid='members-list'
            >
                {(data?.allGroupMembers?.nodes ?? []).map((groupMember: { id: string, userByUserId: { firstname: string, email: string } }) => {
                    return (
                        <li id={groupMember.id}>
                            { groupMember.userByUserId.firstname }: { groupMember.userByUserId.email }
                        </li>
                    )
                })}
            </div>
        </div>
    );
};

export default GroupMembersPage;