import { gql, useMutation } from '@apollo/client';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

const EmailRegisterPage = () => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");

    const navigate = useNavigate();
    const cookies = new Cookies();

    const [createOrGetUser] = useMutation(gql`
        mutation MyMutation(
            $firstname: String!,
            $email: String!,
            $password: String,
            $createUserIfNotExist: Boolean = true
        ) {
            createOrGetUser(
                input: {
                    userEmail: $email,
                    userPassword: $password,
                    userFirstname: $firstname,
                    createUserIfNotExist: $createUserIfNotExist
                }
            ) {
                user {
                    id
                    email
                    token
                    lastname
                    firstname
                }
            }
        }
    `)

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
                <Heading fontSize={'4xl'}>Create a new account</Heading>
            </Stack>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack spacing={4}>
                <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input
                        data-testid='register-email-input'
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl id="firstname">
                    <FormLabel>Firstname</FormLabel>
                    <Input
                        data-testid='register-firstname-input'
                        type="text"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </FormControl>
                <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input
                        data-testid='register-password-input'
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                <Stack spacing={10}>
                    <Button
                        data-testid='register-button-submit'
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.500',
                        }}
                        onClick={async() => {
                            try {
                                const req = await createOrGetUser({
                                    variables: {
                                        email: email,
                                        password: password,
                                        firstname: firstname,
                                    }
                                });
                                if (!req?.data?.createOrGetUser?.user?.firstname) {
                                    navigate(`/users/${req?.data?.createOrGetUser?.user?.id}/auth/profil/email`);
                                    return ;
                                }
                                cookies.set('user_id', req?.data?.createOrGetUser?.user?.id);
                                cookies.set('email', req?.data?.createOrGetUser?.user?.email);
                                cookies.set('token', req?.data?.createOrGetUser?.user?.token);
                                cookies.set('firstname', req?.data?.createOrGetUser?.user?.firstname);
                                cookies.set('lastname', req?.data?.createOrGetUser?.user?.lastname);
                                toast.success('You are now sigin');
                                navigate('/events/');
                            } catch (e) {
                                toast.error('Error, cannot login');
                            }
                        }}
                    >
                        Sign in
                    </Button>
                </Stack>
                </Stack>
            </Box>
            </Stack>
        </Flex>
    );
}

export default EmailRegisterPage;
