import {gql, useQuery} from "@apollo/client";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {IUser} from "../../types/IUser";
import Cookies from "universal-cookie";


interface IEvent {
    id: string,
    name: string,
    dateOfEvent: string,
    status: string
}

interface IEventParticipation {
    id: string,
    user: IUser,
    eventId: string,
    eventByEventId: IEvent
    userByUserId: IUser
}

const MessageriePage = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const { data, loading } = useQuery(
        gql`
            query MyQuery(
                $userId: UUID
            ) {
                allEventParticipations(
                    filter: {
                        userId: {
                            equalTo: $userId
                        }
                    }
                ) {
                    nodes {
                        id
                        eventId
                        eventByEventId {
                            id
                            name
                            dateOfEvent
                            status
                        }
                    }
                }
            }
        `,
        {
            variables: {
                userId: cookies.get('user_id')
            }
        }
    )

    if (loading){
        return (<div></div>)
    }

    return (
        <div>
            {(data?.allEventParticipations?.nodes ?? []).length === 0 &&
                <Alert status='warning'>
                    <AlertIcon />
                    <AlertTitle>To access the messagerie of an event,</AlertTitle>
                    <AlertDescription>you must first say that your are participating or interested.</AlertDescription>
                </Alert>
            }
            <TableContainer>
                <Table
                    variant='simple'
                >
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                            {(data?.allEventParticipations?.nodes ?? []).map((node: IEventParticipation) => (
                                <Tr
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={async() => navigate(`/messagerie/${node?.eventId}/`)}
                                    data-testid={`msg-event-${node?.eventByEventId?.id}`}
                                >
                                    <Td>{node?.eventByEventId.name}</Td>
                                    <Td>{node?.eventByEventId?.status}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default MessageriePage;
