import {Box, Flex} from "@chakra-ui/react";

import {IUser} from "../types/IUser";
import {Quote, QuoteProps} from "./Quote";

export function QuoteList({quotes, currentUserId}: { quotes: Array<any>, currentUserId: string }) {
    return <Flex padding='4' width="100%" alignItems={'flex-start'} flexDirection="column">
        {quotes.map((quote: {
                id: string,
                userId: string,
                content: string,
                createdAt: string,
                userByUserId: IUser
            }, index: number) => {
                const previousMessage = index === 0 ? null : quotes[index - 1];
                const nextMessage = quotes.length - 1 === index ? null : quotes[index + 1];
                let inGroup: QuoteProps["inGroup"] = 'alone'
                if (nextMessage?.userByUserId.id === quote.userByUserId.id &&
                    previousMessage?.userByUserId.id === quote.userByUserId.id
                ) {
                    inGroup = 'middle'
                }
                else if (nextMessage?.userByUserId.id === quote.userByUserId.id) {
                    inGroup = 'first'
                } else if (previousMessage?.userByUserId.id === quote.userByUserId.id) {
                    inGroup = 'last'
                }

                return quote.userId === currentUserId
                    ? <Box margin="0.5" alignSelf="flex-end" flexGrow={0} maxWidth={'80%'} key={`Quote${quote.id}`}>
                        <Quote user={quote.userByUserId} date={quote.createdAt} fromConnectedUser
                               inGroup={inGroup}>
                            {quote.content}
                        </Quote>
                    </Box>
                    : <Box margin="0.5" alignSelf="flex-start" flexGrow={0} maxWidth={'80%'} key={`Quote${quote.id}`}>
                        <Quote user={quote.userByUserId} date={quote.createdAt} fromConnectedUser={false} inGroup={inGroup}>
                            {quote.content}
                        </Quote>
                    </Box>
            }
        )}
    </Flex>
}
