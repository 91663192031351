import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { FoundingStatusEnum } from "../../../../enums/FoundingStatusEnum";
import { Button, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const MAX_TIKET_PRICE = 500;

const FoundingViewPage = () => {

    const { event_id } = useParams();
    const navigate = useNavigate();

    const [ticketPrice, setTicketPrice] = useState<string>('');

    const { data } = useQuery(gql`
        query eventFounding (
            $eventId: UUID!
        ) {
            foundingByEventId(eventId: $eventId) {
                eventId
                price
                id
                status
                eventByEventId {
                    eventParticipationsByEventId {
                        totalCount
                    }
                }
            }
        }
    `, {
        variables: {
            eventId: event_id
        },
        fetchPolicy: 'cache-and-network'
    });

    const [createFounding] = useMutation(gql`
        mutation createFounding(
            $status: FoundingStatus!,
            $eventId: UUID!,
            $price: BigFloat
        ) {
            createFounding(
                input: {
                    founding: {
                        eventId: $eventId,
                        status: $status,
                        price: $price
                    }
                }
            ) {
                founding {
                    id
                }
            }
        }
    `);

    const [updateFoundingById] = useMutation(gql`
        mutation updateFoundingById(
            $status: FoundingStatus!,
            $foundingId: UUID!,
            $price: BigFloat
        ) {
            updateFoundingById(
                input: {
                    foundingPatch: {
                        status: $status,
                        price: $price
                    },
                    id: $foundingId
                }
            ) {
                founding {
                    id
                }
            }
        }
    `);

    const check_price_founding = (): boolean => {
        try {
            const num = parseFloat(ticketPrice);
            if (!ticketPrice || Number.isNaN(num)){
                toast.error('Ticket price cannot be empty');
                return (false);
            }
            if (!ticketPrice){
                toast.error('Ticket price cannot be empty');
                return (false);
            }
            if (num < 0) {
                toast.error('Ticket price cannot be negative');
                return (false);
            }
            if (num > MAX_TIKET_PRICE) {
                toast.error(`Ticket price cannot be more than ${MAX_TIKET_PRICE}`);
                return (false);
            }
            return (true);
        } catch (e) {
            toast.error('Your ticket price is not in a monetary format');
            return (false);
        }
    };

    const activate_founding = async(): Promise<void> => {
        if (!check_price_founding()) {
            return ;
        }

        if (data?.foundingByEventId?.eventByEventId?.eventParticipationsByEventId?.totalCount > 0) {
            toast.error('You cannot activate the founding pot if there is already some participants in the event');
            return ;
        }

        try {
            if (data?.foundingByEventId) {
                await updateFoundingById({
                    variables: {
                        status: FoundingStatusEnum.ACTIVATED,
                        foundingId: data.foundingByEventId.id,
                        price: ticketPrice
                    }
                })
            } else {
                await createFounding({
                    variables: {
                        status: FoundingStatusEnum.ACTIVATED,
                        eventId: event_id,
                        price: ticketPrice
                    }
                })
            }
            toast.success('Succeffully activated the founding for this event');
            navigate(`/events/${event_id}/settings/`);
        } catch (e) {
            console.error(e);
            toast.error('There was an error while actiavting the founding, pleace try again later');
        }
    };

    const deactivate_founding = async(): Promise<void> => {
        try {
            if (data?.foundingByEventId) {
                updateFoundingById({
                    variables: {
                        status: FoundingStatusEnum.DEACTIVATED,
                        foundingId: data.foundingByEventId.id
                    }
                })
            } else {
                createFounding({
                    variables: {
                        status: FoundingStatusEnum.DEACTIVATED,
                        eventId: event_id
                    }
                })
            }
            toast.success('Succeffully deactivated the founding for this event');
            navigate(`/events/${event_id}/settings/`);
        } catch (e) {
            console.error(e);
            toast.error('There was an error while actiavting the founding, pleace try again later');
        }
    };

    useEffect(() => {
        setTicketPrice(data?.foundingByEventId?.price ?? '');
    }, [data]);

    return (
        <div style={{  paddingLeft: '2rem', paddingRight: '2rem' }}>
            <p style={{ paddingTop: '2rem' }}>Founding for this event is currently
                {
                    data?.foundingByEventId?.status === FoundingStatusEnum.ACTIVATED
                    ? `${data?.foundingByEventId?.status?.toLowerCase()} with ${data?.foundingByEventId?.price}€`
                    : 'deactivated'
                }
            </p>
            <div style={{ paddingTop: '2rem' }}>
                {(!data?.foundingByEventId || data?.foundingByEventId?.status === FoundingStatusEnum.DEACTIVATED ) ? (
                    <div>
                        <Text>To activate the founding you must indicate the price in €:</Text>
                        <Input
                            placeholder='price of the ticket'
                            value={ticketPrice}
                            onChange={(e) => setTicketPrice(e.target.value)}
                            data-testid='input-price-ticket'
                        />
                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                            <Button
                                variant='solid'
                                colorScheme='blue'
                                data-testid='activate-founding-event'
                                onClick={() => activate_founding()}
                            >
                                Activate the founding pot for this event
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant='solid'
                            colorScheme='blue'
                            data-testid='deactivate-founding-event'
                            onClick={() => deactivate_founding()}
                        >
                            Deactivate the founding pot for this event
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FoundingViewPage;