import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const UserIsNotLogged = ({
  group_id
}: {
  group_id: string
}) => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: '2rem' }}>
      You have to be logged to access this page
      <Button
        onClick={() => navigate(`/auth/register?nextURL=/groups/${group_id}`)}
      >
        SignUp
      </Button>
    </div>
  );
}

export default UserIsNotLogged;
