const getDateFromDatetime = (datetime: string): string | undefined => {
  if (!datetime) {
      return (undefined);
  }
  const date = datetime.split('T')[0].split('-');
  return (`${date[0]}-${date[1]}-${date[2]}`);
};

const getTimeFromDatetime = (datetime: string): string | undefined => {
  if (!datetime) {
      return (undefined);
  }
  const minutes = datetime.split('T')[1].split('.')[0].split(':');

  return (`${minutes[0]}:${minutes[1]}`);
};

const getTimeCorrect = (data: string) => {
  if (!data) {
      return ('');
  }
  const date = data.split('T')[0].split('-');
  const minutes = data.split('T')[1].split('.')[0].split(':');

  return (`Le ${date[2]}/${date[1]}/${date[0]} à ${minutes[0]}:${minutes[1]}`);
}

const addHoursToDate = (datetime: string, hours_to_add: number): string => {
  if (!datetime){
    return (datetime);
  }
  if (hours_to_add <= 0){
    throw new Error('Hours to add cannot be less than 0');
  }
  const new_date = new Date(datetime);
  new_date.setHours(
    new_date.getHours() + hours_to_add + 2
  ).toString()
  return (new Date(new_date).toISOString());
}

export {
  getDateFromDatetime,
  getTimeFromDatetime,
  getTimeCorrect,
  addHoursToDate
}
