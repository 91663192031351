import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import EventList from "../../../components/EventList";

const EventListPage = () => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    const getEnFormatDate = () => {
        const datetime = new Date().toISOString();
        const [date, hours] = datetime.split('T');
        const date_split = date.split('-');
        const hours_split = hours.split(':');

        const year = date_split[0].padStart(2, '0');
        const month = date_split[1].padStart(2, '0');
        const day = date_split[2].padStart(2, '0');

        const minutes = hours_split[0].padStart(2, '0');
        const secondes = hours_split[1].padStart(2, '0');
        return (`${year}-${month}-${day} ${minutes}:${secondes}`);
    };

    const [checkToken] = useMutation(gql`
        mutation checkToken (
            $userToken: UUID
        ) {
            checkToken(
                input: {
                    appUserToken: $userToken
                }
            ) {
                boolean
            }
        }
    `)

    const { data } = useQuery(gql`
        query getAllEvents(
            $date_now: Datetime
        ) {
            allEvents (
                filter: {
                    or: [
                      {
                        dateOfEvent: {
                        greaterThan: $date_now,
                        }
                      },
                      {
                        dateOfEvent: {
                          isNull: true
                        }
                      }
                    ]
                }
            ) {
                nodes {
                    id
                    address
                    maxParticipants
                    name
                    picture
                    price
                    dateOfEvent
                    status
                }
            }
        }
    `, {
        variables: {
            date_now: getEnFormatDate()
        },
        fetchPolicy: 'cache-and-network'
    });

    const check_user_logged = async() => {
        try {
            await checkToken({
                variables: {
                    userToken: cookies.get('token')
                }
            });
        } catch (e) {
            console.error(e);
            toast.error('Vous devez etre connecté pour acceder a cette page');
            navigate('/');
        }
    };

    useEffect(() => {
        check_user_logged();
    }, [false]);

    return (
        <div>
            <div style={{ textAlign: 'center', paddingTop: '3rem' }}>
                <Button
                    onClick={() => navigate('/events/new/')}
                    variant='solid'
                    colorScheme='blue'
                    data-testid="button-new-event"
                >
                    Create new event
                </Button>
            </div>
            <div>
                {((data?.allEvents?.nodes ?? []).length === 0) &&
                    <p>
                        There is no event for the moment
                    </p>
                }
            </div>
            <EventList
                events={(data?.allEvents?.nodes ?? [])}
            />
        </div>
    );
};

export default EventListPage;
