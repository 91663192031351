import {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import Cookies from "universal-cookie";
import {parseISO} from "date-fns";
import {Alert, AlertDescription, AlertIcon, Box, Button, Container, Flex, Textarea} from "@chakra-ui/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'

import {IMessage} from "../../types/IMessage";
import {QuoteList} from "../../components/QuoteList";

const PER_PAGE = 50;

const EventMessageriePage = () => {
    const lastElementForScroll = useRef<any>(null);
    const textArea = useRef<any>(null);
    const {event_id} = useParams();
    const cookies = new Cookies();

    const [createMessage, { loading: mutationLoading}] = useMutation(
        gql`
            mutation MyMutation (
                $eventId: UUID!,
                $userId: UUID!,
                $content: String!
            ) {
                createMessage(
                    input: {
                        message: {
                            eventId: $eventId,
                            userId: $userId,
                            content: $content
                        }
                    }
                ) {
                    message {
                        id
                        userId
                        content
                        createdAt
                        userByUserId {
                            id
                            firstname
                            lastname
                            email
                            picture
                        }
                    }
                }
            }
        `
    );

    const {data, fetchMore, refetch} = useQuery(
        gql`
            query MyQuery(
                $eventId: UUID!,
                $offset: Int!,
                $first: Int!,
            ) {
                allMessages(
                    filter: {
                        eventId: {
                            equalTo: $eventId
                        }
                    }
                    orderBy: CREATED_AT_DESC
                    offset: $offset
                    first: $first
                ) {
                    nodes {
                        id
                        userId
                        content
                        createdAt
                        userByUserId {
                            id
                            firstname
                            lastname
                            email
                            picture
                        }
                    }
                    totalCount
                }
            }
        `,
        {
            pollInterval: 5000,
            variables: {
                eventId: event_id,
                offset: 0,
                first: PER_PAGE
            }
        }
    );

    const allMessages = (data?.allMessages?.nodes || []).slice().sort((nodeA: IMessage, nodeB: IMessage) => {
        return parseISO(nodeA.createdAt).getTime() - parseISO(nodeB.createdAt).getTime()
    });

    useEffect(() => {
        const lastElementChild = lastElementForScroll?.current;
        lastElementChild?.scrollIntoView();
    }, [data?.allMessages?.totalCount]);

    async function submitMessage() {
        try {
            if (!textArea.current.value.trim()) {
                toast.error('Your message cannot be empty');
                return;
            }
            await createMessage({
                variables: {
                    eventId: event_id,
                    userId: cookies.get('user_id') ?? '',
                    content: textArea.current.value
                }
            });
            textArea.current.value = '';
            refetch({
                eventId: event_id,
            })
        } catch (e) {
            console.error(e);
            toast.error('There was an error while sending your message, please try again later');
        }
    }

    return (
        <Flex flexDirection="column" height="100%">
            <Container maxW='2xl' centerContent padding="4" overflowY="auto" paddingBottom={'7rem'}>
                {allMessages.length === 0 &&
                    <Alert status='info'>
                        <AlertIcon/>
                        <AlertDescription>There is no message for the moment.</AlertDescription>
                    </Alert>
                }
                {(allMessages.length > 0 && data?.allMessages?.totalCount !== allMessages.length) &&
                    <Button
                        variant="outline"
                        colorScheme="blue"
                        onClick={() => {
                            fetchMore({
                                variables: {
                                    offset: allMessages.length
                                },
                                updateQuery: (previousResult, {fetchMoreResult}) => {
                                    if (!fetchMoreResult) return previousResult;

                                    return {
                                        // Merge the new results with the previous results
                                        // Customize this based on your data structure

                                        allMessages: {
                                            nodes: [...previousResult.allMessages.nodes, ...fetchMoreResult.allMessages.nodes],
                                            totalCount: fetchMoreResult.allMessages.totalCount,
                                        },
                                    };
                                },
                            })
                        }}
                    >
                        Load more messages
                    </Button>
                }

                <QuoteList quotes={allMessages} currentUserId={cookies.get('user_id')}/>

                <div ref={lastElementForScroll}/>
            </Container>

            <Box padding="4" backgroundColor="gray.100" flexGrow={0} flexShrink={0} position={'absolute'} bottom={0} width={'100%'}>
                <Container centerContent position="relative">
                    <Flex gap="4" alignItems="center" width="100%">
                        <Textarea
                            flexGrow={1}
                            backgroundColor="white"
                            alignContent="stretch"
                            ref={textArea}
                            onKeyUpCapture={function (event) {
                                // Command key not working on OSX :/
                                if (event.ctrlKey && event.keyCode === 13) {
                                    submitMessage()
                                }
                            }}
                            data-testid='input-user-messagerie'
                        />
                        <Button
                            isLoading={mutationLoading}
                            loadingText='Send'
                            flexGrow={0}
                            variant='solid'
                            colorScheme='blue'
                            leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                            onClick={() => submitMessage()}
                            data-testid='buttom-submit-messagerie'
                        >
                            Send
                        </Button>
                    </Flex>
                </Container>
            </Box>
        </Flex>
    )
};

export default EventMessageriePage;
