const SubTitle = ({
  value
}: {
  value: string
}) => {
  return (
    <span
      style={{
        color: '#ADA6A6',
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
    }}
    >
      {value}
    </span>
  );
};

export default SubTitle;
