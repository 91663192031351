import { gql, useMutation } from "@apollo/client";
import { Button, Heading, Select, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { surveyTypeEnum } from "../../../../enums/surveyTypeEnum";
import { surveyTimelineEnum } from "../../../../enums/surveyTimelineEnum";

const NewSurveyPage = () => {

    const [createSurvey] = useMutation(gql`
        mutation createSurvey(
            $eventId: UUID!,
            $createdBy: UUID!,
            $surveyType: SurveyType!,
            $surveyValue: SurveyValue!
        ) {
            createSurvey(
                input: {
                    survey: {
                        eventId: $eventId,
                        createdBy: $createdBy,
                        surveyType: $surveyType,
                        surveyValue: $surveyValue
                    }
                }
            ) {
                survey {
                    id
                }
            }
        }
    `);

    const [surveyType] = useState<string>(surveyTypeEnum.DATE);
    const [surveyValue, setSurveyValue] = useState<string>();

    const navigate = useNavigate();
    const { event_id } = useParams();
    const cookies = new Cookies();

    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
            <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                <Heading>New survey creation</Heading>
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Goal of the survey</Text>
                <Select
                    variant='outline'
                    placeholder='Select the goal for this survey'
                    defaultValue={surveyTypeEnum.DATE}
                    disabled
                >
                    <option value={surveyTypeEnum.DATE}>Choosing the date</option>
                </Select>
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Timeline to choose the date</Text>
                <Select
                    variant='outline'
                    placeholder='Select the timeline to choose the date'
                    value={surveyValue}
                    onChange={(e) => setSurveyValue(e.target.value)}
                    id='new-survey-timeline'
                >
                    <option value={surveyTimelineEnum.SEVEN_DAYS} data-testid='new-survey-timeline-option-0'>Next 7 days</option>
                    <option value={surveyTimelineEnum.FOURTEEN_DAYS} data-testid='new-survey-timeline-option-1'>Next 14 days</option>
                    <option value={surveyTimelineEnum.THIRTY_DAYS} data-testid='new-survey-timeline-option-2'>Next 30 days</option>
                </Select>
            </div>
            <div
                style={{ paddingTop: '3rem', textAlign: 'center' }}
            >
                <Button
                    data-testid='submit-button-new-survey'
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        if (surveyType === surveyTypeEnum.DATE && !surveyValue){
                            toast.error('You have to choose the timneline');
                            return;
                        }
                        try {
                            const req: any = await createSurvey({
                                variables: {
                                    surveyType: surveyType,
                                    surveyValue: surveyValue,
                                    eventId: event_id,
                                    createdBy: cookies.get('user_id')
                                }
                            });
                            console.log(req);
                            navigate(`/events/${event_id}/settings/survey/${req?.data?.createSurvey?.survey?.id}`)
                        } catch (e) {
                            console.error(e);
                            toast.error('Error while creating survey');
                        }
                    }}
                >
                    Validate
                </Button>
            </div>
        </div>
    );
};

export default NewSurveyPage;
