import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import {Box, ChakraProvider, Flex} from '@chakra-ui/react'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SignIn from './pages/SignIn';
import EventListPage from './pages/Events/list/List';
import EventViewPage from './pages/Events/view/View';
import EventNewPage from './pages/Events/new/New';
import EditEventPage from './pages/Events/settings/edit/Edit';
import SettingsEventPage from './pages/Events/settings/settings'
import EmailLoginPage from './pages/auth/Login';
import EmailRegisterPage from './pages/auth/Register';
import UserEvents from './pages/users/events';
import MessageriePage from './pages/messagerie/View';
import AuthProfilFirstname from './pages/auth/profil/AuthProfilFirstname';
import SurveyList from './pages/Events/settings/survey/list';

import EventMessageriePage from './pages/messagerie/List';
import NewSurveyPage from './pages/Events/settings/survey/new';
import ViewSurveyPage from './pages/Events/settings/survey/view';
import SurveyVotePage from './pages/Events/survey/vote';
import FoundingViewPage from './pages/Events/settings/founding/view';
import GroupListPage from './pages/groups/list/list';
import NewGroupPage from './pages/groups/new/new';
import GroupViewPage from './pages/groups/view/view';
import SettingGroupPage from './pages/groups/settings/view';
import GroupMembersPage from './pages/groups/settings/members';

import SignInPage from './pages/auth/Signin';
import SignUpPage from './pages/auth/Signup';
import Menu from './components/menu';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
    uri: (process.env.REACT_APP_ENV === 'DEV'
        ? 'http://localhost:5452/graphql'
        : 'https://api.sakafet.fr/graphql'
    ),
    cache: new InMemoryCache(),
});


function Layout(props: { children: ReactNode }) {
    
    const showNavbar = (): boolean => {
      if (window.location.href.search('/auth/signin') >= 0) {
        return (false);
      }
      if (window.location.href.search('/auth/signup') >= 0) {
        return (false);
      }
      return (true);
    };

    return (
      <Flex height="100%" flexDirection="column" alignItems="stretch">
      {showNavbar() && <Menu/>}
        <Box overflow="auto">
          {props.children}
        </Box>
      </Flex>
  );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Layout>
                <Outlet/>
            </Layout>
        ),
        children: [
            {
                path: '/',
                element: <SignIn/>
            },
            {
                path: '/events/',
                element: <EventListPage/>
            },
            {
                path: '/events/:event_id/',
                element: <EventViewPage/>
            },
            {
                path: '/events/new/',
                element: <EventNewPage/>
            },
            {
                path: '/events/:event_id/settings/edit/',
                element: <EditEventPage/>
            },
            {
                path: '/events/:event_id/settings/',
                element: <SettingsEventPage/>
            },
            {
                path: '/auth/login/',
                element: <EmailLoginPage/>
            },
            {
              path: '/auth/signin',
              element: <SignInPage/>
            },
            {
              path: '/auth/signup',
              element: <SignUpPage/>
            }, 
            {
                path: '/auth/register',
                element: <EmailRegisterPage/>
            },
            {
                path: '/users/:user_id/events',
                element: <UserEvents/>
            },
            {
                path: '/messagerie/',
                element: <MessageriePage/>
            },
            {
                path: '/messagerie/:event_id/',
                element: <EventMessageriePage/>
            },
            {
                path: '/users/:user_id/auth/profil/email',
                element: <AuthProfilFirstname/>
            },
            {
                path: '/events/:event_id/settings/survey',
                element: <SurveyList/>
            },
            {
                path: '/events/:event_id/settings/survey/new',
                element: <NewSurveyPage/>
            },
            {
                path: '/events/:event_id/settings/survey/:survey_id',
                element: <ViewSurveyPage/>
            },
            {
                path: '/events/:event_id/survey/:survey_id/vote',
                element: <SurveyVotePage/>
            },
            {
                path: '/events/:event_id/settings/founding',
                element: <FoundingViewPage/>
            },
            {
                path: '/groups/',
                element: <GroupListPage/>
            },
            {
                path: '/groups/new/',
                element: <NewGroupPage/>
            },
            {
                path: '/groups/:group_id',
                element: <GroupViewPage/>
            },
            {
                path: '/groups/:group_id/settings',
                element: <SettingGroupPage/>
            },
            {
                path: '/groups/:group_id/settings/members',
                element: <GroupMembersPage/>
            }
        ]
    },
])


root.render(
    <React.StrictMode>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            theme="light"
        />
        <ChakraProvider>
            <ApolloProvider client={client}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ''}>
                    <RouterProvider router={router} />
                </GoogleOAuthProvider>
            </ApolloProvider>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
