import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import UserIsMember from "./components/UserIsMember.Component";
import UserIsLoggedButNotMember from "./components/UserIsLoggedButNotMember.Component";
import UserIsNotLogged from "./components/UserIsNotLogged.Component";

const GroupViewPage = () => {

    const { group_id } = useParams();
    const cookies = new Cookies();

    const { data, refetch } = useQuery(gql`
        query GroupViewPage (
            $userId: UUID!
        ) {
            userById(
                id: $userId
            ) {
                groupMembersByUserId {
                    nodes {
                        groupId
                    }
                }
                id
            }
        }
    `, {
        variables: {
            userId: cookies.get('user_id')
        },
        fetchPolicy: 'cache-and-network'
    });

    const is_user_member = () => {
        return (
            (data?.userById?.groupMembersByUserId?.nodes ?? []).find((groupMember: { groupId: string }) => groupMember?.groupId === group_id)
        );
    }

    const is_user_logged = () => {
        return (data?.userById?.id);
    }

    if (is_user_member()) {
        return <UserIsMember/>
    }
    else if (is_user_logged() && !is_user_member()) {
        return <UserIsLoggedButNotMember refetch={refetch} />
    }
    else {
        return <UserIsNotLogged group_id={group_id ?? ''}/>
    }
};

export default GroupViewPage;
