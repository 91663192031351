const SubmitButton = ({
  title,
  width = '25rem',
  onSubmit
}: {
  title: string,
  width?: string,
  onSubmit: () => void
}) => {
  return (
    <button
      style={{
        backgroundColor: '#A162F7',
        color: 'white',
        padding: '0.7rem',
        borderRadius: 14,
        borderWidth: 0,
        fontSize: '1rem',
        width: width,
        fontFamily: 'sans-serif'
      }}
      onClick={onSubmit}
    >
      {title}
    </button>
  );
};

export default SubmitButton;
