import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from '@apollo/client';
import Cookies from 'universal-cookie';

const MenuItem = ({
  value,
  onClick,
  currentURL
}: {
  value: string,
  onClick: () => void,
  currentURL: string
}) => {
  if (currentURL.search(value.toLowerCase()) >= 0) {
    return (
      <div
        style={{
          marginRight: 5,
          textDecoration: 'underline',
          color: '#A162F7',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        {value}
      </div> 
    );
  }
  return (
    <div
      style={{ marginRight: 5, cursor: 'pointer' }}
      onClick={onClick}
    >
      {value}
    </div>
  );
};

const MenuData = [
  {
    name: 'Events',
    url: '/events/'
  },
  {
    name: 'Groups',
    url: '/groups/'
  },
  {
    name: 'Messagerie',
    url: '/messagerie/'
  }
]

const DropDownPicture = ({
  showDropdown,
  onClick
}: {
  showDropdown: boolean,
  onClick: () => void
}) => {
  return (
    <>
      {showDropdown &&
        <div
          style={{
            position: 'absolute',
            right: 20
          }}
        >
          <div
            style={{
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
              zIndex: 1,
              cursor: 'pointer'
            }}
            onClick={onClick}
          >
            Disconnect
          </div>
        </div>
      }
    </>
  );
};

const Menu = () => {
  
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [showDropdown, setShowDropDown] = useState<boolean>(false);
  const [isuserLogged, setIsUserLogged] = useState<boolean>(false);

  const { data } = useQuery(gql`
    query Menu (
      $userId: UUID!
    ) {
      userById(
        id: $userId
      ){
        picture
      }
    }
  `, {
    variables: {
        userId: cookies.get('user_id')
      }
  });

  const [checkToken] = useMutation(gql`
      mutation checkToken (
          $userToken: UUID
      ) {
          checkToken(
              input: {
                  appUserToken: $userToken
              }
          ) {
              boolean
          }
      }
  `)

  const check_user_logged = async() => {
      try {
          await checkToken({
              variables: {
                  userToken: cookies.get('token')
              }
          });
          setIsUserLogged(true);
      } catch (e) {
          console.error(e);
      }
  };

  const disconnectUser = () => {
    Object.keys(cookies.getAll()).forEach((cookieName) => {
        cookies.remove(cookieName, {
            path: '/'
        });
    });
    setIsUserLogged(false);
    setShowDropDown(false);
    navigate('/');
  };

  useEffect(() => {
      check_user_logged();
  }, [window.location.href]);

  if (!isuserLogged){
    return (
      <div></div>
    );
  };

  return (
    <div
      style={{
        borderWidth: 1,
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div
        style={{ display: 'flex', marginTop: 5 }}
      >
        {MenuData.map((menuDataItem: { name: string, url: string }, index: number) => (  
          <MenuItem
            key={index}
            value={menuDataItem.name}
            currentURL={window.location.href}
            onClick={() => navigate(menuDataItem.url)}
          />
        ))}
      </div>
      <div>
        <img
          src={
            data?.userById?.picture ?? 'https://cdn.pixabay.com/photo/2018/01/05/00/20/test-image-3061864_1280.png'
          }
          width={40}
          style={{ borderRadius: '100%', cursor: 'pointer', height: 40 }}
          onClick={() => setShowDropDown(!showDropdown)}
        />
        <DropDownPicture showDropdown={showDropdown} onClick={disconnectUser} />
      </div>
    </div>
  );
};

export default Menu;
