import { useState } from 'react';
import { SubTitle, Title, Link, TextInput, SubmitButton } from '../../components/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { gql, useMutation } from '@apollo/client';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

const SignInPage = () => {
  
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const navigate = useNavigate();
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();

  const [createOrGetUser] = useMutation(gql`
      mutation MyMutation(
          $firstname: String! = "",
          $email: String!,
          $password: String,
          $createUserIfNotExist: Boolean = false
      ) {
          createOrGetUser(
              input: {
                  userEmail: $email,
                  userPassword: $password,
                  userFirstname: $firstname,
                  createUserIfNotExist: $createUserIfNotExist
              }
          ) {
              user {
                  id
                  email
                  token
                  lastname
                  picture
                  firstname
              }
          }
      }
  `)

  const [createLogin] = useMutation(
      gql`
          mutation createLogin(
              $userId: UUID!
          ) {
              createLogin(
                  input: {
                      login: {
                          userId: $userId
                      }
                  }
              ) {
                  login {
                      id
                  }
              }
          }
      `
  );

  const handleSubmit = async() => {
    try {
        const req = await createOrGetUser({
            variables: {
                email: email,
                password: password,
            }
        });
        if (!req?.data?.createOrGetUser?.user?.firstname) {
            navigate(`/users/${req?.data?.createOrGetUser?.user?.id}/auth/profil/email`);
            return ;
        }
        cookies.set('user_id', req?.data?.createOrGetUser?.user?.id);
        cookies.set('email', req?.data?.createOrGetUser?.user?.email);
        cookies.set('token', req?.data?.createOrGetUser?.user?.token);
        cookies.set('firstname', req?.data?.createOrGetUser?.user?.firstname);
        cookies.set('lastname', req?.data?.createOrGetUser?.user?.lastname);
        toast.success('You are now sigin');
        navigate(searchParams.get('nextURL') ?? '/events/');
    } catch (e) {
        toast.error('Error, cannot login');
    }
  };

  const handleGoogleLogin = async(credentialResponse: CredentialResponse) => {
    const info: { email: string, given_name: string, family_name: string, picture: string } = jwt_decode(credentialResponse.credential ?? '');
    const req = await createOrGetUser({
        variables: {
            userEmail: info?.email,
            userFirstname: info?.given_name,
            userLastname: info?.family_name,
            userPicture: info?.picture
        }
    });
    if (!req?.data?.createOrGetUser?.user?.firstname) {
        navigate(`/users/${req?.data?.createOrGetUser?.user?.id}/auth/profil/email`);
        return ;
    }
    cookies.set('user_id', req?.data?.createOrGetUser?.user?.id);
    cookies.set('email', req?.data?.createOrGetUser?.user?.email);
    cookies.set('token', req?.data?.createOrGetUser?.user?.token);
    cookies.set('firstname', req?.data?.createOrGetUser?.user?.firstname);
    cookies.set('lastname', req?.data?.createOrGetUser?.user?.lastname);
    console.log(req?.data?.createOrGetUser?.user?.id);
    await createLogin({
        variables: {
            userId: req?.data?.createOrGetUser?.user?.id
        }
    })
    navigate('/events/');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh'
      }}
    >
      <div>
        <Title value='Welcome back'/>
        <div
          style={{ marginBottom: 15 }}
        >
          <SubTitle value="Don't have an account? " />
          <Link value='Sign up' url={() => navigate('/auth/signup')}/>
        </div>
        <div>
          <TextInput title='Email' placeholder='Your email address' value={email} onChange={setEmail} />
          <TextInput title='Password' placeholder='Your password' value={password} onChange={setPassword} secure={true} />
        </div>
        <div style={{ marginTop: 10 }}>
          <SubmitButton title='Sign In' onSubmit={handleSubmit} />
        </div>
        <div style={{ marginTop: 15 }}>
          <GoogleLogin
             onSuccess={handleGoogleLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
