import { Dispatch, SetStateAction } from "react";

const TextInput = ({
  title,
  value,
  onChange,
  placeholder = '',
  width = '25rem',
  secure = false
}: {
  title: string,
  value: string,
  onChange: Dispatch<SetStateAction<string>>,
  placeholder?: string,
  width?: string,
  secure?: boolean
}) => {
  return (
    <div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 2,
          fontWeight: 'bold',
          fontFamily: 'sans-serif'
        }}
       >
        {title}
      </div>
      <input
        type={secure ? 'password' : 'text'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        style={{
          borderRadius: 8,
          padding: 10,
          borderColor: '#928888',
          borderWidth: 'thin',
          width: width,
          marginBottom: 10
        }}
      />
    </div>
  );
};

export default TextInput;
