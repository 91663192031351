import { useState } from 'react';
import { Button, Heading, Input, Select, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { gql, useMutation, useQuery } from '@apollo/client';
import Cookies from 'universal-cookie';
import { GroupFrequencyEnum } from '../../../enums/groupFrequencyEnum';

const NewGroupPage = () => {
    const [groupName, setGroupName] = useState('');
    const [activity, setActivity] = useState('');
    const [frequency, setFrequency] = useState('');

    const cookies = new Cookies();
    const navigate = useNavigate();

    const { data } = useQuery(gql`
        query allActivities {
            allActivities {
                nodes {
                    id
                    name
                }
            }
        }
    `);

    const [createGroup] = useMutation(gql`
        mutation userCreateGroup (
            $groupName: String!,
            $activityId: UUID!,
            $frequency: GroupFrequency!,
            $userId: UUID!
        ) {
            userCreateGroup(
                input: {
                    groupName: $groupName,
                    activityId: $activityId,
                    frequency: $frequency,
                    userId: $userId
                }
            ) {
                group {
                    id
                }
            }
        }
    `);

    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
            <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                <Heading>New Group Creation</Heading>
            </div>
            <div style={{ paddingTop: '2rem' }}>
                <Text>Group Name</Text>
                <Input
                    type="text"
                    data-testid='new-group-input-name'
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />
            </div>
            <div style={{ paddingTop: '1rem' }}>
                <Text>Activity</Text>
                <Select
                    id='new-group-select-activity'
                    placeholder="Select an activity"
                    value={activity}
                    onChange={(e) => setActivity(e.target.value)}
                >
                    {(data?.allActivities?.nodes ?? []).map((activity: { id: string, name: string }) => (
                        <option value={activity.id}>{activity.name}</option>
                    ))}
                </Select>
            </div>
            <div style={{ paddingTop: '1rem' }}>
                <Text>Frequency</Text>
                <Select
                    id='new-group-select-frequency'
                    placeholder="Select a frequency"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                >
                    <option value={GroupFrequencyEnum.EVERY_WEEK}>Every week</option>
                    <option value={GroupFrequencyEnum.EVERY_TWO_WEEKS}>Every two weeks</option>
                </Select>
            </div>
            <div style={{ paddingTop: '3rem', textAlign: 'center' }}>
                <Button
                    data-testid='new-group-submit-button'
                    variant='solid'
                    colorScheme='blue'
                    onClick={async() => {
                        if (!groupName || !activity || !frequency) {
                            toast.error('Please fill in all fields.');
                            return;
                        }
                        try {
                            await createGroup({
                                variables: {
                                    groupName: groupName,
                                    activityId: activity,
                                    frequency: frequency,
                                    userId: cookies.get('user_id')
                                }
                            })
                            navigate('/groups/');
                        } catch (error) {
                            console.error(error);
                            toast.error('Error while creating the group');
                        }
                    }}
                >
                    Create Group
                </Button>
            </div>
        </div>
    );
};

export default NewGroupPage;
